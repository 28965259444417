import React from "react";
import PropTypes from "prop-types";
import "./row.css";
/*
  Usage – can be passed a reverse prop to swap the columns.
  Can be passed a doubleGap prop to double the gap width.  Standard is
  3.75rem (60px).
  Can be passed a divider prop to create a vertical separator and some padding
  between children.
  Use with NO MORE THAN TWO direct children, which can contain text, headings,
  images, etc.
  In most cases, Row components will be placed inside a NestedContainer
  component.

  <Row7525
    reverse
    doubleGap
    divider
    verticalCenter
  >
    <div><h1>here's some content</h1></div>
    <div><h1>here's some content</h1></div>
  </Row7525>

*/

const Row7525 = ({ reverse, doubleGap, divider, verticalCenter, children }) => (
  <div
    className={`flex-row flex-row--${reverse ? "75-25--rev" : "75-25"} flex-row--${verticalCenter ? "vertical-center" : "normal"} ${
      doubleGap ? "gap--double" : "gap--standard"
    } ${divider ? "divider" : ""}`}
  >
    {children}
  </div>
);

Row7525.defaultProps = {
  reverse: false,
  doubleGap: false,
  divider: false,
  verticalCenter: false,
};

Row7525.propTypes = {
  reverse: PropTypes.bool,
  doubleGap: PropTypes.bool,
  divider: PropTypes.bool,
  verticalCenter: PropTypes.bool,
};

export default Row7525;

import * as React from "react";
// Global Layout
import Layout from "../components/layout/layout";

// Components
import Hero from "../components/hero/hero";
import FullWidthContainer from "../components/full-width-container/full-width-container";
import NestedContainer from "../components/nested-container/nested-container";
import Row6633 from "../components/row/row-66-33";
import Row7525 from "../components/row/row-75-25";
import RowEqual from "../components/row/row-equal";
import SectionHeader from "../components/section-header/section-header";
import { StaticImage } from "gatsby-plugin-image";
import TestimonialCallout from "../components/testimonial-callout/testimonial-callout";
import VideoModal from "../components/video-modal/video-modal";
import classImg from "../images/partners/Video-Sharing-Heartfelt-Stories.jpg";

const DemoPage = () => {
  return (
    <Layout pageTitle="Demo">
      <FullWidthContainer background={`var(--white-to-blue)`}>
        <Hero
          title="Mission Report"
          intro="All Rhode Islanders—from the youngest babies to the oldest great-grandparents—deserve the chance to live a healthy life. To grow up with a roof over our heads. Dinner at our own kitchen table. We deserve high-quality, affordable medical care and therapy when we need it. The people of Blue Cross & Blue Shield of Rhode Island are working to make that happen. The health and well-being of Rhode Islanders is what we live for."
          community
          customers
          partners
        >
          <StaticImage
            src="../images/stock.jpg"
            alt="test"
            aspectRatio={19 / 17}
          />
        </Hero>
        <NestedContainer>
          <h2>Demo of video modal</h2>
          <VideoModal
            ariaHideApp={false}
            caption={[<u>Watch now</u>]}
            posterImage={classImg}
            videoSource="//www.youtube.com/embed/EngW7tLk6R8"
          />
        </NestedContainer>
      </FullWidthContainer>
      <FullWidthContainer background={"#F7F6F2"}>
        <h1>Full width container with solid background</h1>
        <NestedContainer
          background={"#E6E5E1"}
          border={"thick-white"}
          hasShadow
        >
          <SectionHeader
            reportType={"financials"}
            title={"Section Header"}
            intro={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sed orci quis ante condimentum fermentum non eget urna. In hac habitasse platea dictumst. Maecenas varius orci commodo, dignissim justo tempor, pretium ligula. Morbi imperdiet ante erat, a feugiat ex sollicitudin convallis. Sed malesuada nunc nec orci egestas aliquam. Cras rutrum convallis augue, ut lobortis mauris tincidunt eget. Fusce vestibulum ligula in turpis placerat ornare. Cras sed ultricies sem. Pellentesque eget ipsum nisi. Sed dapibus egestas metus id lacinia."
            }
          />
          <div>
            <h2>Nested Component with thick white border and shadow</h2>
            <p>
              Well, I'll say this for him - he's sure of himself. Captain, why
              are we out here chasing comets? I can't. As much as I care about
              you, my first duty is to the ship. Computer, lights up! I'd like
              to think that I haven't changed those things, sir. Wait a minute -
              you've been declared dead. You can't give orders around here.
              Worf, It's better than music. It's jazz. Mr. Worf, you sound like
              a man who's asking his friend if he can start dating his sister.
            </p>
          </div>
          <div>
            <p>
              You're going to be an interesting companion, Mr. Data. Now we know
              what they mean by 'advanced' tactical training. Flair is what
              marks the difference between artistry and mere competence.
              Computer, belay that order. I've had twelve years to think about
              it. And if I had it to do over again, I would have grabbed the
              phaser and pointed it at you instead of them. In all trust, there
              is the possibility for betrayal. I'm afraid I still don't
              understand, sir. I guess it's better to be lucky than good. Some
              days you get the bear, and some days the bear gets you. Maybe if
              we felt any human loss as keenly as we feel one of those close to
              us, human history would be far less bloody.
            </p>
          </div>
          <TestimonialCallout
            quote="This is a game-changer for the nurse-patient relationship."
            author="Smita Mazumdar"
            role="RN, BSN, CCM, BCBSRI director of care management"
          />
          <TestimonialCallout
            grid
            url="/customers"
            quote="This is a game-changer for the nurse-patient relationship."
            author="Smita Mazumdar"
            role="RN, BSN, CCM, BCBSRI director of care management"
          />
          <Row6633>
            <div>
              <h3>A 66-33 column section</h3>
              <p>
                Earl Grey tea, watercress sandwiches... and Bularian canapés?
                Are you up for promotion? I am your worst nightmare!
              </p>
            </div>
            <div>
              <StaticImage src="../images/stock.jpg" alt="Stock" />
            </div>
          </Row6633>
          <Row6633 reverse>
            <div>
              <StaticImage src="../images/stock.jpg" alt="Stock" />
            </div>
            <div>
              <h3>A 66-33 reversed column section</h3>
              <p>
                Earl Grey tea, watercress sandwiches... and Bularian canapés?
                Are you up for promotion? I am your worst nightmare!
              </p>
            </div>
          </Row6633>
          <Row6633 doubleGap>
            <div>
              <h3>A 66-33 column section with a double-gap</h3>
              <p>
                Earl Grey tea, watercress sandwiches... and Bularian canapés?
                Are you up for promotion? I am your worst nightmare!
              </p>
            </div>
            <div>
              <StaticImage src="../images/stock.jpg" alt="Stock" />
            </div>
          </Row6633>
        </NestedContainer>
        <NestedContainer background="#B3E5A4" border={"thin-grey"} hasShadow>
          <Row7525>
            <div>
              <h4>A 75-25 column section</h4>
              <p>
                You're going to be an interesting companion, Mr. Data. Now we
                know what they mean by 'advanced' tactical training. Flair is
                what marks the difference between artistry and mere competence.
                Computer, belay that order. I've had twelve years to think about
                it. And if I had it to do over again, I would have grabbed the
                phaser and pointed it at you instead of them. In all trust,
                there is the possibility for betrayal. I'm afraid I still don't
                understand, sir. I guess it's better to be lucky than good. Some
                days you get the bear, and some days the bear gets you. Maybe if
                we felt any human loss as keenly as we feel one of those close
                to us, human history would be far less bloody.
              </p>
            </div>
            <div>
              <StaticImage
                src="../images/community/Image-Covid.png"
                alt="covid"
              />
            </div>
          </Row7525>
          <Row7525 reverse divider>
            <div>
              <StaticImage
                src="../images/community/Image-Covid.png"
                alt="covid"
              />
            </div>
            <div>
              <h4>A 75-25 reversed column section with divider</h4>
              <p>
                You're going to be an interesting companion, Mr. Data. Now we
                know what they mean by 'advanced' tactical training. Flair is
                what marks the difference between artistry and mere competence.
                Computer, belay that order. I've had twelve years to think about
                it. And if I had it to do over again, I would have grabbed the
                phaser and pointed it at you instead of them. In all trust,
                there is the possibility for betrayal. I'm afraid I still don't
                understand, sir. I guess it's better to be lucky than good. Some
                days you get the bear, and some days the bear gets you. Maybe if
                we felt any human loss as keenly as we feel one of those close
                to us, human history would be far less bloody.
              </p>
            </div>
          </Row7525>
        </NestedContainer>
      </FullWidthContainer>
      <FullWidthContainer background={`var(--blue-to-white)`}>
        <h1>Full width container with gradient background</h1>
        <NestedContainer>
          <TestimonialCallout
            unstyled
            quote="This is a game-changer for the nurse-patient relationship."
            author="Smita Mazumdar"
            role="RN, BSN, CCM, BCBSRI director of care management"
          />
        </NestedContainer>
        <NestedContainer background={"#fff"} hasShadow>
          <div>
            <h2>Nested Component with no border and shadow</h2>
            <RowEqual>
              <div>
                <h4>Equal row with two direct children (50\50)</h4>
                <p>
                  A lot of things can change in twelve years, Admiral. What's a
                  knock-out like you doing in a computer-generated gin joint
                  like this?
                </p>
              </div>
              <div>
                <h4>Equal row with two direct children (50\50)</h4>
                <p>
                  Mr. Worf, you do remember how to fire phasers? I think you've
                  let your personal feelings cloud your judgement.
                </p>
              </div>
            </RowEqual>
            <RowEqual>
              <div>
                <h4>Equal row with three direct children (33/33/33)</h4>
                <p>
                  A lot of things can change in twelve years, Admiral. What's a
                  knock-out like you doing in a computer-generated gin joint
                  like this?
                </p>
              </div>
              <div>
                <h4>Equal row with three direct children (33/33/33)</h4>
                <p>
                  Mr. Worf, you do remember how to fire phasers? I think you've
                  let your personal feelings cloud your judgement.
                </p>
              </div>
              <div>
                <h4>Equal row with three direct children (33/33/33)</h4>
                <p>
                  Mr. Worf, you do remember how to fire phasers? I think you've
                  let your personal feelings cloud your judgement.
                </p>
              </div>
            </RowEqual>
          </div>
          <div>
            <p>
              You're going to be an interesting companion, Mr. Data. Now we know
              what they mean by 'advanced' tactical training. Flair is what
              marks the difference between artistry and mere competence.
              Computer, belay that order. I've had twelve years to think about
              it. And if I had it to do over again, I would have grabbed the
              phaser and pointed it at you instead of them. In all trust, there
              is the possibility for betrayal. I'm afraid I still don't
              understand, sir. I guess it's better to be lucky than good. Some
              days you get the bear, and some days the bear gets you. Maybe if
              we felt any human loss as keenly as we feel one of those close to
              us, human history would be far less bloody.
            </p>
          </div>
          <div>
            <h2>
              Another heading here, as there may be multiple components inside
              the nested component
            </h2>
            <p>
              You're going to be an interesting companion, Mr. Data. Now we know
              what they mean by 'advanced' tactical training. Flair is what
              marks the difference between artistry and mere competence.
              Computer, belay that order. I've had twelve years to think about
              it. And if I had it to do over again, I would have grabbed the
              phaser and pointed it at you instead of them. In all trust, there
              is the possibility for betrayal. I'm afraid I still don't
              understand, sir. I guess it's better to be lucky than good. Some
              days you get the bear, and some days the bear gets you. Maybe if
              we felt any human loss as keenly as we feel one of those close to
              us, human history would be far less bloody.
            </p>
            <h2>Another heading/sub-section</h2>
            <StaticImage src="../images/stock.jpg" alt="Stock" />
          </div>
        </NestedContainer>
      </FullWidthContainer>
      <FullWidthContainer background={"#003E51"} dark>
        <h1>Full width container with solid dark blue background</h1>
        <NestedContainer>
          <h2>
            Nested Component with no props so that it gets the same background
            treatment as outer
          </h2>
          <p>
            Well, I'll say this for him - he's sure of himself. Captain, why are
            we out here chasing comets? I can't. As much as I care about you, my
            first duty is to the ship. Computer, lights up! I'd like to think
            that I haven't changed those things, sir. Wait a minute - you've
            been declared dead. You can't give orders around here. Worf, It's
            better than music. It's jazz. Mr. Worf, you sound like a man who's
            asking his friend if he can start dating his sister.
          </p>
          <h4 style={{ color: "#fff" }}>Equal Row with divider prop</h4>
          <RowEqual divider>
            <div>
              Well, I'll say this for him - he's sure of himself. Captain, why
              are we out here chasing comets? I'd like to think that I haven't
              changed those things, sir.
            </div>
            <div>
              Well, I'll say this for him - he's sure of himself. Captain, why
              are we out here chasing comets? I'd like to think that I haven't
              changed those things, sir.
            </div>
            <div>
              Well, I'll say this for him - he's sure of himself. Captain, why
              are we out here chasing comets? I'd like to think that I haven't
              changed those things, sir.
            </div>
          </RowEqual>
        </NestedContainer>
      </FullWidthContainer>
    </Layout>
  );
};

export default DemoPage;
